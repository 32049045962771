const firebaseConfig = {
	apiKey: "AIzaSyBEDoTRe8XrTS5Xw45onWiapjdCf6fkvzI",
	authDomain: "personalweb-f9db0.firebaseapp.com",
	projectId: "personalweb-f9db0",
	storageBucket: "personalweb-f9db0.appspot.com",
	messagingSenderId: "500510270562",
	appId: "1:500510270562:web:847ff0acb52f87837c9d3b",
	measurementId: "G-6034QLHYJC",
};

export default firebaseConfig;